<script>
import AppBanner from "@/components/shared/AppBanner";
// import ProjectsGrid from "../components/projects/ProjectsGrid.vue";
// import Button from "../components/reusable/Button.vue";

export default {
  name: "Home",
  components: {
    AppBanner,
    // ProjectsGrid,
    // Button,
  },
};
</script>

<template>
  <div class="container mx-auto">
    <div class="my-10 sm:my-16 mb-8 md:mb-14 mx-auto max-w-2xl text-center">
      <h1
        class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-5xl"
      >
        Morse Code Translator
      </h1>
      <p
        class="mt-3 sm:mt-6 text-base sm:text-xl leading-8 text-gray-600 dark:text-gray-400"
      >
        Translate text and morse code with our free online tool! Enter text in
        either box to instantly convert. Press the sound button to play as
        audio.
      </p>
      <!-- <div class="mt-10 flex items-center justify-center gap-x-6">
        <a
          href="#"
          class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >Get started</a
        >
        <a href="#" class="text-sm font-semibold leading-6 text-gray-900"
          >Learn more <span aria-hidden="true">→</span></a
        >
      </div> -->
    </div>
    <!-- Banner -->
    <AppBanner class="mb-5 sm:mb-8" />

    <!-- Projects -->
    <!-- <ProjectsGrid /> -->
    <div
      class="container mx-auto px-4 text-left text-gray-800 dark:text-gray-300"
    >
      <section class="my-12">
        <h2 class="text-2xl font-semibold mb-4">Why Morse Code Translator?</h2>
        <p class="text-lg mb-6">
          You might be wondering why you should opt for our platform. Let's
          delve into the specific advantages that set our Morse Code Translator
          apart from the rest of the crowd.
        </p>

        <h2 class="text-2xl font-semibold mb-4">Morse Code Alphabet</h2>

        <table class="min-w-4xl mx-auto bg-white mb-6">
          <thead class="bg-gray-800 text-white">
            <tr>
              <th class="w-1/8 py-2 px-4">Character</th>
              <th class="w-1/8 py-2 px-4">Morse Code</th>
              <th class="w-1/8 py-2 px-4">Character</th>
              <th class="w-1/8 py-2 px-4">Morse Code</th>
            </tr>
          </thead>
          <tbody class="text-gray-700">
            <tr>
              <td class="py-2 px-4">A</td>
              <td class="py-2 px-4">.-</td>
              <td class="py-2 px-4">B</td>
              <td class="py-2 px-4">-...</td>
            </tr>
            <tr>
              <td class="py-2 px-4">C</td>
              <td class="py-2 px-4">-.-.</td>
              <td class="py-2 px-4">D</td>
              <td class="py-2 px-4">-..</td>
            </tr>
            <tr>
              <td class="py-2 px-4">E</td>
              <td class="py-2 px-4">.</td>
              <td class="py-2 px-4">F</td>
              <td class="py-2 px-4">..-.</td>
            </tr>
            <tr>
              <td class="py-2 px-4">G</td>
              <td class="py-2 px-4">--.</td>
              <td class="py-2 px-4">H</td>
              <td class="py-2 px-4">....</td>
            </tr>
            <tr>
              <td class="py-2 px-4">I</td>
              <td class="py-2 px-4">..</td>
              <td class="py-2 px-4">J</td>
              <td class="py-2 px-4">.---</td>
            </tr>
            <tr>
              <td class="py-2 px-4">K</td>
              <td class="py-2 px-4">-.-</td>
              <td class="py-2 px-4">L</td>
              <td class="py-2 px-4">.-..</td>
            </tr>
            <tr>
              <td class="py-2 px-4">M</td>
              <td class="py-2 px-4">--</td>
              <td class="py-2 px-4">N</td>
              <td class="py-2 px-4">-.</td>
            </tr>
            <tr>
              <td class="py-2 px-4">O</td>
              <td class="py-2 px-4">---</td>
              <td class="py-2 px-4">P</td>
              <td class="py-2 px-4">.--.</td>
            </tr>
            <tr>
              <td class="py-2 px-4">Q</td>
              <td class="py-2 px-4">--.-</td>
              <td class="py-2 px-4">R</td>
              <td class="py-2 px-4">.-.</td>
            </tr>
            <tr>
              <td class="py-2 px-4">S</td>
              <td class="py-2 px-4">...</td>
              <td class="py-2 px-4">T</td>
              <td class="py-2 px-4">-</td>
            </tr>
            <tr>
              <td class="py-2 px-4">U</td>
              <td class="py-2 px-4">..-</td>
              <td class="py-2 px-4">V</td>
              <td class="py-2 px-4">...-</td>
            </tr>
            <tr>
              <td class="py-2 px-4">W</td>
              <td class="py-2 px-4">.--</td>
              <td class="py-2 px-4">X</td>
              <td class="py-2 px-4">-..-</td>
            </tr>
            <tr>
              <td class="py-2 px-4">Y</td>
              <td class="py-2 px-4">-.--</td>
              <td class="py-2 px-4">Z</td>
              <td class="py-2 px-4">--..</td>
            </tr>
            <tr>
              <td class="py-2 px-4">0</td>
              <td class="py-2 px-4">-----</td>
              <td class="py-2 px-4">1</td>
              <td class="py-2 px-4">.----</td>
            </tr>
            <tr>
              <td class="py-2 px-4">2</td>
              <td class="py-2 px-4">..---</td>
              <td class="py-2 px-4">3</td>
              <td class="py-2 px-4">...--</td>
            </tr>
            <tr>
              <td class="py-2 px-4">4</td>
              <td class="py-2 px-4">....-</td>
              <td class="py-2 px-4">5</td>
              <td class="py-2 px-4">.....</td>
            </tr>
            <tr>
              <td class="py-2 px-4">6</td>
              <td class="py-2 px-4">-....</td>
              <td class="py-2 px-4">7</td>
              <td class="py-2 px-4">--...</td>
            </tr>
            <tr>
              <td class="py-2 px-4">8</td>
              <td class="py-2 px-4">---..</td>
              <td class="py-2 px-4">9</td>
              <td class="py-2 px-4">----.</td>
            </tr>
          </tbody>
        </table>

        <ul class="list-disc pl-8 text-lg">
          <li class="mb-3">
            Fast and Accurate Translations: Our algorithms are optimized for
            quick and precise Morse code translations.
          </li>
          <li class="mb-3">
            User-friendly Interface: With a design that is intuitive and
            easy-to-navigate, you will find everything you need right at your
            fingertips.
          </li>
          <li class="mb-3">
            Multi-platform Support: Whether you're on a PC, a tablet, or a
            smartphone, our platform offers seamless cross-device compatibility.
          </li>
          <li class="mb-3">
            Educational Features: Interactive quizzes, video tutorials, and a
            comprehensive learning module make Morse code education both
            engaging and effective.
          </li>
          <li class="mb-3">
            Customizable Settings: From changing themes to setting preferences,
            we offer a variety of customization options to suit your needs.
          </li>
        </ul>
      </section>

      <h2 class="text-2xl font-semibold mb-4">Features</h2>
      <section class="my-12">
        <h2 class="text-2xl font-semibold mb-4">Benefits</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 class="text-xl font-semibold mb-2">Time-Saving</h3>
            <p class="text-lg mb-6">
              Our Morse Code Translator is designed to provide fast, real-time
              translations. Gone are the days when you would need to jot down
              Morse sequences and manually decipher them. This translates to
              substantial time savings, whether you're a student, a
              professional, or a hobbyist.
            </p>
          </div>
          <div>
            <h3 class="text-xl font-semibold mb-2">Accessibility</h3>
            <p class="text-lg mb-6">
              The platform is designed with accessibility in mind. Voice input
              and output functionalities mean that even those who are visually
              impaired can benefit from our service. This level of accessibility
              ensures that Morse code remains inclusive and universal.
            </p>
          </div>
          <div>
            <h3 class="text-xl font-semibold mb-2">Educational</h3>
            <p class="text-lg mb-6">
              We go beyond mere translation. The educational modules included in
              the platform offer a deep dive into the history of Morse code, its
              evolution, and its current-day applications. Interactive quizzes
              and video tutorials make the learning process interactive and fun.
            </p>
          </div>
          <div>
            <h3 class="text-xl font-semibold mb-2">Secure and Private</h3>
            <p class="text-lg mb-6">
              In this age of data breaches and privacy concerns, we understand
              the importance of security. All translations are processed on your
              local device, ensuring that your data remains confidential and
              secure.
            </p>
          </div>
        </div>
      </section>

      <section class="my-12">
        <h2 class="text-2xl font-semibold mb-4">Technology</h2>
        <p class="text-lg mb-6">
          What powers the Ultimate Morse Code Translator? Our commitment to
          utilizing cutting-edge technology ensures that we offer a top-notch
          experience to all our users.
        </p>
        <ul class="list-disc pl-8 text-lg">
          <li class="mb-3">
            WebAssembly for high-speed computations, ensuring your translations
            are completed in the blink of an eye.
          </li>
          <li class="mb-3">
            Local Storage for saving your preferences and customization
            settings, so you don't have to set them up every time.
          </li>
          <li class="mb-3">Vue for a responsive and dynamic user interface.</li>
          <li class="mb-3">
            Web Audio API for seamless audio playback and download.
          </li>
        </ul>
      </section>

      <section class="my-12">
        <h2 class="text-2xl font-semibold mb-4">Use Cases</h2>
        <p class="text-lg mb-6">
          The applications of Morse code, and by extension, our Morse Code
          Translator, are far-reaching. Here are some scenarios where our tool
          can be invaluable.
        </p>
        <ul class="list-disc pl-8 text-lg">
          <li class="mb-3">
            Educational Institutions: Students can leverage our platform as a
            reliable learning resource for Morse code.
          </li>
          <li class="mb-3">
            Outdoor Enthusiasts: For trekkers, hikers, and campers, our tool can
            serve as a reliable backup communication medium.
          </li>
          <li class="mb-3">
            Telecommunication Industry: Professionals can use our tool for quick
            translations and encoding, streamlining workflow.
          </li>
          <li class="mb-3">
            Hobbyists: Radio operators, coding enthusiasts, and history buffs
            can integrate Morse code into their various projects.
          </li>
          <li class="mb-3">
            Emergency Services: In critical situations where modern
            communication might fail, our tool can provide an alternative way to
            send distress signals or messages.
          </li>
        </ul>
      </section>
    </div>
    <!-- Load more projects button -->
    <div class="mt-10 sm:mt-20 flex justify-center">
      <!-- <router-link
        to="/projects"
        class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
        aria-label="More Projects"
      >
        <Button title="More Projects" />
      </router-link> -->
    </div>
  </div>
</template>

<style scoped></style>
