<script>
import { ref } from "vue";
import morse from "morse-decoder"; // Uncomment when using the actual library
import feather from "feather-icons";

export default {
  name: "Home",
  data: () => {
    return {
      theme: "",
      openSettings: false,
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    console.log("Coming soon!", morse);
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
  },
  updated() {
    feather.replace();
  },
  methods: {},
  setup() {
    const text = ref("");
    const morseCode = ref("");
    const dash = ref("-");
    const space = ref("/");
    const seperator = ref(" ");
    const dot = ref(".");
    const unitPeriod = ref(0.1);
    const frequency = ref(500);
    const waveform = ref("sine");
    const languages = ref({
      "Default (ASCII)": 1,
      Numbers: 2,
      Punctuation: 3,
      Latin: 4,
      Cyrillic: 5,
      Greek: 6,
      Hebrew: 7,
      Arabic: 8,
      Persian: 9,
      Japanese: 10,
      Korean: 11,
      Thai: 12,
    });
    const alphabet = ref(4);

    const copyTextToClipboard = async (words) => {
      try {
        await navigator.clipboard.writeText(words);
        console.log("Text successfully copied");
      } catch (err) {
        alert("Error copying! " + err);
      }
    };

    const convertToMorse = () => {
      morseCode.value = morse.encode(text.value, {
        priority: alphabet.value,
        dash: dash.value,
        dot: dot.value,
        separator: seperator.value,
        space: space.value,
      });
    };

    const convertToText = () => {
      if (morseCode.value) {
        text.value = morse.decode(morseCode.value, {
          priority: alphabet.value,
          dash: "-",
          dot: ".",
          separator: " ",
          space: "/",
        });
      } else {
        text.value = null;
      }
    };

    const download = () => {
      if (text.value) {
        const audio = morse.audio(text.value, {
          unit: unitPeriod.value, // period of one unit, in seconds, 1.2 / c where c is speed of transmission, in words per minute
          fwUnit: 0, // period of one Farnsworth unit to control intercharacter and interword gaps
          oscillator: {
            type: waveform.value, // sine, square, sawtooth, triangle
            frequency: frequency.value, // value in hertz
          },
        });
        audio.exportWave();
      }
    };

    const playText = (words) => {
      var msg = new SpeechSynthesisUtterance();
      msg.text = words;
      window.speechSynthesis.speak(msg);
    };

    const playAudio = (code) => {
      const audio = morse.audio(code, {
        unit: unitPeriod.value, // period of one unit, in seconds, 1.2 / c where c is speed of transmission, in words per minute
        fwUnit: 0, // period of one Farnsworth unit to control intercharacter and interword gaps
        oscillator: {
          type: waveform.value, // sine, square, sawtooth, triangle
          frequency: frequency.value, // value in hertz
          //   onended: function () {
          //     // event that fires when the tone stops playing
          //     console.log("ended");
          //   },
        },
      });
      audio.play();
    };

    return {
      text,
      morseCode,
      convertToMorse,
      convertToText,
      playAudio,
      playText,
      copyTextToClipboard,
      download,
      dash,
      space,
      seperator,
      dot,
      unitPeriod,
      frequency,
      waveform,
      alphabet,
      languages,
    };
  },
};
</script>

<template>
  <section class="px-2">
    <div
      class="items-center flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 text-black dark:text-white"
    >
      <div class="flex flex-col w-full">
        <div>
          <div
            class="flex items-center"
            aria-orientation="horizontal"
            role="tablist"
          >
            <!-- Selected: "bg-gray-100 text-gray-900 hover:bg-gray-200", Not Selected: "bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900" -->
            <button
              id="tabs-1-tab-1"
              class="bg-gray-200 text-gray-900 rounded-md border border-transparent px-3 py-1.5 text-sm font-bold"
              aria-controls="tabs-1-panel-1"
              role="tab"
              type="button"
            >
              Text:
            </button>

            <!-- These buttons are here simply as examples and don't actually do anything. -->
            <div class="ml-auto flex items-center space-x-5">
              <div class="flex items-center">
                <button
                  @click="playText(text)"
                  type="button"
                  class="-m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Play Text Audio</span>
                  <i data-feather="volume-2" class="w-6"></i>
                </button>
              </div>
              <div class="flex items-center">
                <button
                  @click="copyTextToClipboard(text)"
                  type="button"
                  class="-m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Copy Text</span>
                  <i data-feather="clipboard" class="w-5"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div
              id="tabs-1-panel-1"
              class="-m-0.5 rounded-lg p-0.5"
              aria-labelledby="tabs-1-tab-1"
              role="tabpanel"
              tabindex="0"
            >
              <label for="text" class="sr-only">Text</label>
              <div>
                <textarea
                  v-model="text"
                  @input="convertToMorse"
                  name="text"
                  id="text"
                  class="h-40 md:h-48 dark:bg-white/5 dark:text-white dark:ring-white/10 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 text-lg sm:leading-6"
                  placeholder="Enter text to translate it to morse code"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <i
        data-feather="repeat"
        class="hidden h-10 w-10 md:h-12 md:w-12 pt-2 pb-2 md:pb-0 text-blue-500"
      ></i>

      <div class="flex flex-col w-full">
        <div>
          <div
            class="flex items-center"
            aria-orientation="horizontal"
            role="tablist"
          >
            <!-- Selected: "bg-gray-100 text-gray-900 hover:bg-gray-200", Not Selected: "bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-900" -->
            <button
              id="tabs-1-tab-1"
              class="bg-gray-200 text-gray-900 rounded-md border border-transparent px-3 py-1.5 text-sm font-bold"
              aria-controls="tabs-1-panel-2"
              role="tab"
              type="button"
            >
              Morse Code:
            </button>

            <!-- These buttons are here simply as examples and don't actually do anything. -->
            <div class="ml-auto flex items-center space-x-5">
              <div class="flex items-center">
                <button
                  @click="playAudio(text)"
                  type="button"
                  class="-m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Play Morse Code Audio</span>
                  <i data-feather="volume-2" class="w-6"></i>
                </button>
              </div>
              <div class="flex items-center">
                <button
                  @click="download"
                  type="button"
                  class="-m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Copy Morse Code</span>
                  <i data-feather="download" class="w-5"></i>
                </button>
              </div>
              <div class="flex items-center">
                <button
                  @click="copyTextToClipboard(morseCode)"
                  type="button"
                  class="-m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <span class="sr-only">Copy Morse Code</span>
                  <i data-feather="clipboard" class="w-5"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="mt-2">
            <div
              id="tabs-1-panel-2"
              class="-m-0.5 rounded-lg p-0.5"
              aria-labelledby="tabs-1-tab-2"
              role="tabpanel"
              tabindex="0"
            >
              <label for="morse" class="sr-only">Text</label>
              <div>
                <textarea
                  v-model="morseCode"
                  @input="convertToText"
                  name="morse"
                  id="morse"
                  class="h-40 md:h-48 dark:bg-white/5 dark:text-white dark:ring-white/10 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 text-lg sm:leading-6"
                  placeholder="Enter morse code to translate it to text"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      @click="openSettings = !openSettings"
      class="cursor-pointer text-center flex mt-8 text-base text-slate-700 hover:text-slate-400 dark:text-slate-100 items-center"
    >
      <i data-feather="settings" class="h-4 mr-1"></i>
      Advanced Settings
    </div>

    <div v-if="openSettings" class="flex flex-wrap text-left my-4">
      <div class="w-1/4 p-2">
        <label
          for="Alphabet"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
          >Alphabet</label
        >
        <div class="mt-2">
          <select
            @input="convertToMorse"
            v-model="alphabet"
            id="Alphabet"
            name="Alphabet"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm dark:bg-white/5 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option v-for="(val, lang) in languages" :key="val" :value="val">
              {{ lang.charAt(0).toUpperCase() + lang.substr(1) }}
            </option>
          </select>
        </div>
      </div>

      <div class="w-1/4 p-2">
        <label
          for="UnitPeriod"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
          >Unit Period</label
        >
        <div class="mt-2">
          <input
            v-model="unitPeriod"
            @input="convertToMorse"
            type="number"
            name="UnitPeriod"
            id="Unit Period"
            autocomplete="address-level1"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm dark:bg-white/5 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="w-1/4 p-2">
        <label
          for="Frequency (Hz)"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
          >Frequency (Hz)</label
        >
        <div class="mt-2">
          <input
            v-model="frequency"
            @input="convertToMorse"
            type="number"
            name="Frequency (Hz)"
            id="Frequency (Hz)"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm dark:bg-white/5 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="w-1/4 p-2">
        <label
          for="Waveform"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
          >Waveform</label
        >
        <div class="mt-2">
          <select
            v-model="waveform"
            id="Waveform"
            name="Waveform"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm dark:bg-white/5 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value="sine">Sine</option>
            <option value="sawtooth">Sawtooth</option>
            <option value="square">Square</option>
            <option value="triangle">Triangle</option>
          </select>
        </div>
      </div>

      <div class="w-1/4 p-2">
        <label
          for="Dash"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
          >Dash</label
        >
        <div class="mt-2">
          <input
            @input="convertToMorse"
            v-model="dash"
            type="text"
            name="Dash"
            id="Dash"
            autocomplete="address-level1"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm dark:bg-white/5 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="w-1/4 p-2">
        <label
          for="Dot"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
          >Dot</label
        >
        <div class="mt-2">
          <input
            @input="convertToMorse"
            v-model="dot"
            type="text"
            name="Dot"
            id="Dot"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm dark:bg-white/5 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="w-1/4 p-2">
        <label
          for="Space"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
          >Space</label
        >
        <div class="mt-2">
          <input
            @input="convertToMorse"
            v-model="space"
            type="text"
            name="Space"
            id="Space"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm dark:bg-white/5 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div class="w-1/4 p-2">
        <label
          for="Seperator"
          class="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-400"
          >Seperator</label
        >
        <div class="mt-2">
          <input
            @input="convertToMorse"
            v-model="seperator"
            type="text"
            name="Seperator"
            id="Seperator"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm dark:bg-white/5 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          />
        </div>
      </div>
    </div>

    <!-- Banner left contents -->
    <div class="w-full md:w-1/3 text-left">
      <!-- <h1
        class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
      >
        Morse Code Translator
      </h1>
      <p
        class="font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-gray-400"
      >
        Translate text or audio to morse code and hear what it sounds like!
      </p>
      <div class="flex justify-center sm:block">
        <a
          download="Stoman-Resume.pdf"
          href="/files/Stoman-Resume.pdf"
          class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
          aria-label="Download Resume"
        >
          <i
            data-feather="arrow-down-circle"
            class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
          ></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100"
            >Translate now!</span
          ></a
        >
      </div> -->
    </div>

    <!-- Banner right illustration -->
    <!-- <div class="w-full md:w-2/3 text-right float-right">
      <img
        v-if="theme === 'light'"
        src="@/assets/images/developer.svg"
        alt="Developer"
      />
      <img v-else src="@/assets/images/developer-dark.svg" alt="Developer" />
    </div> -->
  </section>
</template>

<style scoped></style>
